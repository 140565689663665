import { useEffect, useState } from "react";
import Accordion from "../common/Accordion";
import LazyLoadImage from "../common/LazyLoadImage";

const VnsHealth = ({
  setElementScrollTop,
  clickedBtn,
  setClickedBtn,
  visibility,
  setVisibility,
}) => {
    const [vnslogo, setvnslogo] = useState("");
    const [vnscolor2, setvnscolor2] = useState("");
    const [vnsgraphics, setvnsgraphics] = useState("");
    const [vnssocial, setvnssocial] = useState("");

    useEffect(() => {
        setTimeout(() => {
            setvnslogo("/img/vnslogo.mp4");
            setvnscolor2("/img/vnscolor2.mp4");
            setvnsgraphics("/img/vnsgraphics.mp4");
            setvnssocial("/img/vnssocial.mp4");
        }, 2000);
    }, []);
    
    
  return (
    <Accordion
      title={"VNS Health"}
      id="vnshealth"
      clickedBtn={clickedBtn}
      setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
      visibility={visibility}
      setVisibility={(visibility) => setVisibility(visibility)}
      setElementScrollTop={(offset) => setElementScrollTop(offset)}
    >
      <p>
        The Visiting Nurse Service of New York (also known as VNSNY) was reinventing itself as VNS Health to better reflect the new, forward-thinking organization it had become. Shedding the clutter of the many names and logos it had accumulated over the past 125 years, we helped streamline the visual expression of this iconic legacy brand to be more flexible, simple to execute, and accessible. We created a new logo to signal change and help connect on a more emotional level with all audiences.
           <br />
                <font color="#D3D3D3">
                    Developed by Hugo Collective w/Christian Dierig, Donna
                    Hadfield & Paco Aguayo. <br />
                    Photography by Benjamin Norman and VNS Health
                    
                </font>
      </p>
          <div className="flex flex-col">
            
                  <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/vnshealth_casestudy19.jpg"
            className="w-[50%] h-full float-right object-cover max-md:w-full"
          />
        </div>
               <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/vnshealth_casestudy20.jpg"
            className="w-full h-full object-cover"
          
          />
        </div>
              
              <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                <video
                    src={vnslogo}
                    className="w-full h-full object-cover"
                    autoPlay
                    muted
                    loop
                    playsInline
                    ></video>
                </div>
              <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/vnshealth_casestudy21.jpg"
            className="w-[50%] h-full float-left object-cover max-md:w-full"
          />
        </div>
              
           <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
                alt="..."
                src="/img/vnshealth_casestudy13.jpg"
                className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
                alt="..."
                src="/img/vnshealth_casestudy11.jpg"
                className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <video
                        src={vnscolor2}
                        className="w-full h-full object-cover"
                        autoPlay
                        muted
                        loop
                        playsInline
                   ></video>
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
                alt="..."
                src="/img/vnshealth_casestudy12.jpg"
                className="w-[50%] h-full float-right object-cover max-md:w-full"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
                alt="..."
                src="/img/vnshealth_casestudy04.jpg"
                className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
                alt="..."
                src="/img/vnshealth_casestudy14.jpg"
                className="w-full h-full object-cover"
             />
        </div>
              
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
                alt="..."
                src="/img/vnshealth_casestudy23.jpg"
                className="w-[50%] h-full float-left object-cover max-md:w-full"
             />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <video
                        src={vnsgraphics}
                        className="w-full h-full object-cover"
                        autoPlay
                        muted
                        loop
                        playsInline
                ></video>   
           </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
              alt="..."
                src="/img/vnshealth_casestudy06.jpg"
                className="w-[50%] h-full float-right object-cover max-md:w-full"
             />
        </div>
           <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
                alt="..."
                src="/img/vnshealth_casestudy01.jpg"
                className="w-full h-full object-cover"
             />
        </div>
            <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
              alt="..."
                src="/img/vnshealth_casestudy08.jpg"
                className="w-full h-full object-cover"
             />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
        <video
            src={vnssocial}
                className="w-full h-full object-cover"
                autoPlay
                muted
                loop
                playsInline
            ></video>
           </div>
           <div className="py-4 w-full max-md:py-3 max-sm:py-2">
           <LazyLoadImage
                alt="..."
                src="/img/vnshealth_casestudy10.jpg"
                className="w-[50%] h-full float-left object-cover max-md:w-full"
             />
        </div>
           <div className="py-4 w-full max-md:py-3 max-sm:py-2">
           <LazyLoadImage
                alt="..."
                src="/img/vnshealth_casestudy02.jpg"
                className="w-full h-full object-cover"
              />
        </div>
           <div className="py-4 w-full max-md:py-3 max-sm:py-2">
           <LazyLoadImage
                alt="..."
               src="/img/vnshealth_casestudy05.jpg"
                className="w-full h-full object-cover"
              />
        </div>
            <div className="py-4 w-full max-md:py-3 max-sm:py-2">
            <LazyLoadImage
                alt="..."
                src="/img/vnshealth_casestudy03.jpg"
                className="w-[50%] h-full float-right object-cover max-md:w-full"
              />
        </div>
            <div className="py-4 w-full max-md:py-3 max-sm:py-2">
            <LazyLoadImage
                alt="..."
                src="/img/vnshealth_casestudy15.jpg"
                className="w-full h-full object-cover"
           />
        </div>
              
      </div>
    </Accordion>
  );
};


export default VnsHealth;
