import React from "react";
import jQuery from "jquery";
import Introduction from "./components/section/Introduction";
import Axio from "./components/section/Axio";
import BowieFilm from "./components/section/BowieFilm";
import ConcussionAwarenessNow from "./components/section/ConcussionAwarenessNow";
import FifthSeason from "./components/section/FifthSeason";
import Keurig from "./components/section/Keurig";
import MuseumfurGestaltung from "./components/section/MuseumfurGestaltung";
import MetLife from "./components/section/MetLife";
import StudioVK from "./components/section/StudioVK";
import SeedsofPeace from "./components/section/SeedsofPeace";
import VnsHealth from "./components/section/VnsHealth";
import Vertice from "./components/section/Vertice";
import WainwrightRealty from "./components/section/WainwrightRealty";
import WallAll from "./components/section/WallAll";
import TheWhartonCenter from "./components/section/TheWhartonCenter";
import LogosandMarks from "./components/section/LogosandMarks";

const App = () => {
  const [tempScrollTop, setTempScrollTop] = React.useState(0);
  const [clickedBtn, setClickedBtn] = React.useState(false);
  const [visibility, setVisibility] = React.useState({
    axio: false,
    bowiefilm: false,
    concussion: false,
    fifth: false,
    introduction: false,
    keurig: false,
    logosand: false,
    metlife: false,
    museum: false,
    seeds: false,
    studio: false,
    warton: false,
    vnshealth: false,
    vertice: false,
    wainwright: false,
    wallall: false,
  });

  React.useEffect(() => {
    if (!clickedBtn) {
      setTimeout(() => {
        jQuery("html, body").animate({ scrollTop: 0 });
        setTempScrollTop(0);
      }, 700);
    }

    window.onscroll = () => {
      const backTop = jQuery("#backTop");
      if (window.scrollY > tempScrollTop + 50) {
        backTop.removeClass("hidden");
      } else {
        backTop.addClass("hidden");
      }
    };
  }, [clickedBtn, tempScrollTop]);

  const topFunction = () => {
    jQuery("html, body").animate(
      {
        scrollTop: tempScrollTop,
      },
      500
    );
  };

  return (
    <>
      <div className="text-2xl font-light">
        <div className="w-full h-auto">
          <div className="max-md:text-[1.5rem] max-md:leading-8 max-[375px]:[1.5rem]">
            <Introduction
              clickedBtn={clickedBtn}
              setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
              visibility={visibility.introduction}
              setVisibility={(visibility) =>
                setVisibility({
                  ...visibility,
                  axio: false,
                  bowiefilm: false,
                  concussion: false,
                  fifth: false,
                  introduction: visibility,
                  keurig: false,
                  logosand: false,
                  metlife: false,
                  museum: false,
                  seeds: false,
                  studio: false,
                  warton: false,
                  vnshealth: false,
                  vertice: false,
                  wainwright: false,
                  wallall: false,
                })
              }
              setElementScrollTop={(offset) => setTempScrollTop(offset)}
            />
            <Axio
              clickedBtn={clickedBtn}
              setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
              visibility={visibility.axio}
              setVisibility={(visibility) =>
                setVisibility({
                  ...visibility,
                  axio: visibility,
                  bowiefilm: false,
                  concussion: false,
                  fifth: false,
                  introduction: false,
                  keurig: false,
                  logosand: false,
                  metlife: false,
                  museum: false,
                  seeds: false,
                  studio: false,
                  warton: false,
                  vnshealth: false,
                  vertice: false,
                  wainwright: false,
                  wallall: false,
                })
              }
              setElementScrollTop={(offset) => setTempScrollTop(offset)}
            />
            <BowieFilm
              clickedBtn={clickedBtn}
              setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
              visibility={visibility.bowiefilm}
              setVisibility={(visibility) =>
                setVisibility({
                  ...visibility,
                  axio: false,
                  bowiefilm: visibility,
                  concussion: false,
                  fifth: false,
                  introduction: false,
                  keurig: false,
                  logosand: false,
                  metlife: false,
                  museum: false,
                  seeds: false,
                  studio: false,
                  warton: false,
                  vnshealth: false,
                  vertice: false,
                  wainwright: false,
                  wallall: false,
                })
              }
              setElementScrollTop={(offset) => setTempScrollTop(offset)}
            />
            <ConcussionAwarenessNow
              clickedBtn={clickedBtn}
              setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
              visibility={visibility.concussion}
              setVisibility={(visibility) =>
                setVisibility({
                  ...visibility,
                  axio: false,
                  bowiefilm: false,
                  concussion: visibility,
                  fifth: false,
                  introduction: false,
                  keurig: false,
                  logosand: false,
                  metlife: false,
                  museum: false,
                  seeds: false,
                  studio: false,
                  warton: false,
                  vnshealth: false,
                  vertice: false,
                  wainwright: false,
                  wallall: false,
                })
              }
              setElementScrollTop={(offset) => setTempScrollTop(offset)}
            />
            <FifthSeason
              clickedBtn={clickedBtn}
              setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
              visibility={visibility.fifth}
              setVisibility={(visibility) =>
                setVisibility({
                  ...visibility,
                  axio: false,
                  bowiefilm: false,
                  concussion: false,
                  fifth: visibility,
                  introduction: false,
                  keurig: false,
                  logosand: false,
                  metlife: false,
                  museum: false,
                  seeds: false,
                  studio: false,
                  warton: false,
                  vnshealth: false,
                  vertice: false,
                  wainwright: false,
                  wallall: false,
                })
              }
              setElementScrollTop={(offset) => setTempScrollTop(offset)}
            />
            <Keurig
              clickedBtn={clickedBtn}
              setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
              visibility={visibility.keurig}
              setVisibility={(visibility) =>
                setVisibility({
                  ...visibility,
                  axio: false,
                  bowiefilm: false,
                  concussion: false,
                  fifth: false,
                  introduction: false,
                  keurig: visibility,
                  logosand: false,
                  metlife: false,
                  museum: false,
                  seeds: false,
                  studio: false,
                  warton: false,
                  vnshealth: false,
                  vertice: false,
                  wainwright: false,
                  wallall: false,
                })
              }
              setElementScrollTop={(offset) => setTempScrollTop(offset)}
            />
            <MuseumfurGestaltung
              clickedBtn={clickedBtn}
              setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
              visibility={visibility.museum}
              setVisibility={(visibility) =>
                setVisibility({
                  ...visibility,
                  axio: false,
                  bowiefilm: false,
                  concussion: false,
                  fifth: false,
                  introduction: false,
                  keurig: false,
                  logosand: false,
                  metlife: false,
                  museum: visibility,
                  seeds: false,
                  studio: false,
                  warton: false,
                  vnshealth: false,
                  vertice: false,
                  wainwright: false,
                  wallall: false,
                })
              }
              setElementScrollTop={(offset) => setTempScrollTop(offset)}
            />
            <MetLife
              clickedBtn={clickedBtn}
              setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
              visibility={visibility.metlife}
              setVisibility={(visibility) =>
                setVisibility({
                  ...visibility,
                  axio: false,
                  bowiefilm: false,
                  concussion: false,
                  fifth: false,
                  introduction: false,
                  keurig: false,
                  logosand: false,
                  metlife: visibility,
                  museum: false,
                  seeds: false,
                  studio: false,
                  warton: false,
                  vnshealth: false,
                  vertice: false,
                  wainwright: false,
                  wallall: false,
                })
              }
              setElementScrollTop={(offset) => setTempScrollTop(offset)}
            />
            <StudioVK
              clickedBtn={clickedBtn}
              setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
              visibility={visibility.studio}
              setVisibility={(visibility) =>
                setVisibility({
                  ...visibility,
                  axio: false,
                  bowiefilm: false,
                  concussion: false,
                  fifth: false,
                  introduction: false,
                  keurig: false,
                  logosand: false,
                  metlife: false,
                  museum: false,
                  seeds: false,
                  studio: visibility,
                  warton: false,
                  vnshealth: false,
                  vertice: false,
                  wainwright: false,
                  wallall: false,
                })
              }
              setElementScrollTop={(offset) => setTempScrollTop(offset)}
            />
            <SeedsofPeace
              clickedBtn={clickedBtn}
              setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
              visibility={visibility.seeds}
              setVisibility={(visibility) =>
                setVisibility({
                  ...visibility,
                  axio: false,
                  bowiefilm: false,
                  concussion: false,
                  fifth: false,
                  introduction: false,
                  keurig: false,
                  logosand: false,
                  metlife: false,
                  museum: false,
                  seeds: visibility,
                  studio: false,
                  warton: false,
                  vnshealth: false,
                  vertice: false,
                  wainwright: false,
                  wallall: false,
                })
              }
              setElementScrollTop={(offset) => setTempScrollTop(offset)}
            />
              <VnsHealth
              clickedBtn={clickedBtn}
              setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
              visibility={visibility.vnshealth}
              setVisibility={(visibility) =>
                setVisibility({
                  ...visibility,
                  axio: false,
                  bowiefilm: false,
                  concussion: false,
                  fifth: false,
                  introduction: false,
                  keurig: false,
                  logosand: false,
                  metlife: false,
                  museum: false,
                  seeds: false,
                  studio: false,
                  warton: false,
                  vnshealth: visibility,
                  vertice: false,
                  wainwright: false,
                  wallall: false,
                })
              }
              setElementScrollTop={(offset) => setTempScrollTop(offset)}
            />
            <Vertice
              clickedBtn={clickedBtn}
              setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
              visibility={visibility.vertice}
              setVisibility={(visibility) =>
                setVisibility({
                  ...visibility,
                  axio: false,
                  bowiefilm: false,
                  concussion: false,
                  fifth: false,
                  introduction: false,
                  keurig: false,
                  logosand: false,
                  metlife: false,
                  museum: false,
                  seeds: false,
                  studio: false,
                  warton: false,
                  vnshealth: false,
                  vertice: visibility,
                  wainwright: false,
                  wallall: false,
                })
              }
              setElementScrollTop={(offset) => setTempScrollTop(offset)}
            />
            <WainwrightRealty
              clickedBtn={clickedBtn}
              setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
              visibility={visibility.wainwright}
              setVisibility={(visibility) =>
                setVisibility({
                  ...visibility,
                  axio: false,
                  bowiefilm: false,
                  concussion: false,
                  fifth: false,
                  introduction: false,
                  keurig: false,
                  logosand: false,
                  metlife: false,
                  museum: false,
                  seeds: false,
                  studio: false,
                  warton: false,
                  vnshealth: false,
                  vertice: false,
                  wainwright: visibility,
                  wallall: false,
                })
              }
              setElementScrollTop={(offset) => setTempScrollTop(offset)}
            />
            <WallAll
              clickedBtn={clickedBtn}
              setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
              visibility={visibility.wallall}
              setVisibility={(visibility) =>
                setVisibility({
                  ...visibility,
                  axio: false,
                  bowiefilm: false,
                  concussion: false,
                  fifth: false,
                  introduction: false,
                  keurig: false,
                  logosand: false,
                  metlife: false,
                  museum: false,
                  seeds: false,
                  studio: false,
                  warton: false,
                  vnshealth: false,
                  vertice: false,
                  wainwright: false,
                  wallall: visibility,
                })
              }
              setElementScrollTop={(offset) => setTempScrollTop(offset)}
            />
            <TheWhartonCenter
              clickedBtn={clickedBtn}
              setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
              visibility={visibility.warton}
              setVisibility={(visibility) =>
                setVisibility({
                  ...visibility,
                  axio: false,
                  bowiefilm: false,
                  concussion: false,
                  fifth: false,
                  introduction: false,
                  keurig: false,
                  logosand: false,
                  metlife: false,
                  museum: false,
                  seeds: false,
                  studio: false,
                  warton: visibility,
                  vnshealth: false,
                  vertice: false,
                  wainwright: false,
                  wallall: false,
                })
              }
              setElementScrollTop={(offset) => setTempScrollTop(offset)}
            />
            <LogosandMarks
              clickedBtn={clickedBtn}
              setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
              visibility={visibility.logosand}
              setVisibility={(visibility) =>
                setVisibility({
                  ...visibility,
                  axio: false,
                  bowiefilm: false,
                  concussion: false,
                  fifth: false,
                  introduction: false,
                  keurig: false,
                  logosand: visibility,
                  metlife: false,
                  museum: false,
                  seeds: false,
                  studio: false,
                  warton: false,
                  vnshealth: false,
                  vertice: false,
                  wainwright: false,
                  wallall: false,
                })
              }
              setElementScrollTop={(offset) => setTempScrollTop(offset)}
            />
          </div>
        </div>
      </div>
      <div
        className="hidden fixed bottom-[30px] right-[30px] cursor-pointer w-[50px] h-[50px] bg-cover"
        onClick={topFunction}
        id="backTop"
        title="Go to the top"
      />
    </>
  );
};

export default App;
